export default class XMP {
    buffer = null;
    constructor(source) {
        if (source instanceof ArrayBuffer) {
            this.buffer = source;
        } else {
            throw Error("Wrong input type. Please use ArrayBuffer.")
        }
    }

    static find(source) {
        if (source instanceof ArrayBuffer) {
            var enc = new TextDecoder("utf-8");
            var data = enc.decode(source);
            var part = data.substring(
                data.lastIndexOf("<x:xmpmeta") + 1,
                data.lastIndexOf("x:xmpmeta>")
            );
            if (part) {
                return "<x:xmpmeta" + part + "x:xmpmeta>";
            }

            return false;
        }

        throw Error("Wrong input type. Please use ArrayBuffer.")
    }

    static parse(xmp) {
        if (!xmp) {
            return null;
        }

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmp, "text/xml");
        const out = [];
        const root = xmlDoc.getElementsByTagName("rdf:Description").length ? xmlDoc.getElementsByTagName("rdf:Description") : xmlDoc.getElementsByTagName("rdf:description");

        if (root.length) {
            for (let node of root) {
                node.childNodes.forEach(o => {
                    if (!o.tagName) {
                        return null;
                    }
                    const [ns, tag] = o.tagName.split(':')
                    const attributes = {ns: ns};
                    if (o.hasAttributes()) {
                        o.getAttributeNames().forEach(n => {
                            const val = o.getAttribute(n);
                            if (val && val !== 'None') {
                                attributes[n] = o.getAttribute(n);
                            }
                        })
                    }
                    out.push({key: tag, value: o.innerHTML, ...attributes})
                })
            }
        }
        return out;
    }

    find() {
        return XMP.find(this.buffer);
    }

    parse() {
        let xmp = this.find();
        return XMP.parse(xmp);
    }
}
