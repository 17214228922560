import './assets/scss/theme.scss';
import AnalyzerPage from "./components/AnalyzerPage";
import NavBar from "./components/Layout/NavBar";
import React, {useCallback, useState} from "react";
import {remove, isEmpty} from "lodash";
import {groupByKey} from "./helpers";


function App() {
    const DOCFLOW_GROUP_NAME = "DocFlow";
    const [file, setFile] = useState(false);
    const [metadataGroups, setMetadataGroups] = useState(false);

    const handleImport = useCallback((file, metadata) => {
        const groups = groupByKey(metadata?.parsed, 'ns');
        let docflowMeta = !isEmpty(groups) && groups[DOCFLOW_GROUP_NAME] ? [...groups[DOCFLOW_GROUP_NAME]] : [];

        const pages = (Array.isArray(file) ? file : [file]).map(page => {
            return {...page, fields:  remove(docflowMeta, item => parseInt(item.page) !== page.page)}
        });

        setMetadataGroups(isEmpty(groups) ? false : groups);
        setFile({name: pages[0]?.name, pages: pages});
    }, []);

    const handleRemove = useCallback(() => {
        setFile(false);
        setMetadataGroups(false);
    }, []);

    return (
        <>
            <NavBar hasFile={Boolean(file)} onFileImport={handleImport} onFileRemove={handleRemove} />
            <main className="container-fluid" style={{marginTop: 70}}>
                <AnalyzerPage file={file} onFileImport={handleImport} onFileRemove={handleRemove} metadataGroups={metadataGroups}/>
            </main>
        </>
    );
}

export default App;
