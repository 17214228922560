import {ClickFileUpload, DropFileUpload} from "./FileUpload";


const UploadBox = ({children, onImport}) => {
    return (
        <DropFileUpload onImport={onImport} splitPdf={false}>{children(onImport)}</DropFileUpload>
    );
};

const UploadButton = ({children, onImport, ...props}) => {
    return (
        <ClickFileUpload onImport={onImport} splitPdf={false}>
            <button {...props}>
                <i className="ai-upload" /><span>{children}</span>
            </button>
        </ClickFileUpload>
    );
};

export {
    UploadBox,
    UploadButton,
};