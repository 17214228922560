import {memo, useCallback, useState} from "react";
import {UploadBox, UploadButton} from "./UploadControls";
import MetaDataTable from "./MetaDataTable";
import FilePreview from "./FilePreview";
import DataBox from "./DataBox";
import {useDidUpdate} from "rooks";
import {Modal} from "react-bootstrap";
import {MdFullscreen} from "react-icons/md";
import {BsTrash} from "react-icons/bs";
import nosplit from "../assets/icons/nosplit.png";


const AnalyzerPage = memo(({file, onFileImport, onFileRemove, metadataGroups}) => {
    const SCALE_FACTOR = 0.72;
    const [fileLoaded, setFileLoaded] = useState(false);
    const handleFileLoaded = useCallback(() => setFileLoaded(true), []);

    useDidUpdate(() => {
        if (!Boolean(file)) {
            setFileLoaded(v => false);
        }
    }, [Boolean(file)]);

    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <div>
            {!file
                ?
                    <div className="d-flex justify-content-center">
                        <section className="d-flex align-items-center my-5 py-5" style={{height: "70vh"}}>
                                <UploadBox onImport={onFileImport}>
                                    {onImport => (
                                        <div className="d-flex justify-content-center flex-column text-center p-4">
                                            <h3 className="fw-lighter">Zistite aké štruktúrované medáta obsahuje súbor.</h3>
                                            <img src={nosplit} alt="Nahrať dokument" width="300" height={300} className="my-4 text-center my-0 mx-auto"/>

                                            <h2 className="mt-3">Ťahaj a pusti súbor sem</h2>
                                            <h5>alebo</h5>
                                            <UploadButton onImport={onImport} type="button" className="btn btn-primary btn-lg mt-2">
                                                <span className="ms-2">Nahraj súbor</span>
                                            </UploadButton>
                                        </div>
                                    )}

                                </UploadBox>
                        </section>
                    </div>
                :
                    <div className="row d-flex justify-content-center">
                        <div className="col-sm-12 order-2 col-lg-8 mt-4">
                            <div className="d-flex justify-content-center flex-wrap">
                               {file.pages.map((page, i) => {
                                    return (
                                        <FilePreview
                                            key={page.hash}
                                            mimeType={page.type}
                                            src={page.data}
                                            isLoaded={fileLoaded}
                                            onLoad={handleFileLoaded}
                                            descaleFactor={SCALE_FACTOR}
                                        >
                                            {(scale, isLoaded) => {
                                                return isLoaded ? (
                                                    <div>
                                                         {page.fields.map(field => <DataBox
                                                            scale={scale}
                                                            id={field.key}
                                                            key={field.key}
                                                            {...field}
                                                            descaleFactor={SCALE_FACTOR}
                                                        />)}
                                                    </div>
                                                ) : null
                                            }}
                                        </FilePreview>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="col-sm-12 order-1 col-lg-4">
                            <div className="d-grid text-center d-lg-none mt-3">

                                <button onClick={onFileRemove} className="btn btn-outline-danger btn-lg">
                                    <BsTrash size={24}/>
                                    <span className="ms-2">Odstrániť súbor</span>
                                </button>

                                <button onClick={handleShowModal} className="btn btn-primary btn-lg mt-3">
                                    <MdFullscreen size={24}/>
                                    <span className="ms-2">
                                        Zobraziť <span className="d-none d-md-inline">štruktúrované</span> metadáta
                                    </span>
                                </button>
                            </div>

                            <Modal show={showModal} fullscreen onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Štruktúrované metadáta</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <TablesList metadataGroups={metadataGroups} fileName={file?.name}/>
                                </Modal.Body>
                            </Modal>

                            <aside className="sticky-top d-none d-lg-block" style={{top: "4.5rem"}}>
                                <TablesList metadataGroups={metadataGroups} fileName={file?.name}/>
                            </aside>
                        </div>
                    </div>
            }
        </div>
    );
});

const TablesList = ({metadataGroups, fileName}) => {
    return metadataGroups ? (
        <div className="offcanvas-body" style={{maxHeight: '95vh'}}>
            {
                 Object.keys(metadataGroups).reverse().map((groupName,i)=>{
                    return (
                        <div key={groupName} className="mb-5">
                            <MetaDataTable data={metadataGroups[groupName]} groupName={groupName} fileName={fileName}/>
                        </div>
                      )
                  })
            }
        </div>
    ) :
        <div className="text-center my-5">
            <h3>Žiadne metadáta</h3>
        </div>;
};

export default AnalyzerPage;