import {groupBy, mapValues, omit} from "lodash";
import jsDownload from "downloadjs";
import {parse} from "json2csv";
import {toXML} from "jstoxml"

export const groupByKey = (arr = [], key = '') => {
    return mapValues(groupBy(arr, key), filteredList => filteredList.map(car => omit(car, key)));
};

export const downloadJson = (data, name) => {
    data = new Blob([JSON.stringify(data)], {
        type: 'application/json'
    });
    return jsDownload(data, `${name}_json.json`, "text/json");
};

export const downloadCsv = (data, columns, name) => {
    const opts = { columns };

    try {
        data = new Blob([parse(data, opts)], {
            type: 'text/csv'
        })
        return jsDownload(data, `${name}_csv.csv`, "text/csv");
    } catch (err) {
        alert("Vyskytla sa chyba!");
    }
};

export const downloadXml = (data, name, namespace) => {
    const xmlOptions = {
      header: true,
      indent: "  ",
    };

    data = toXML({
        _name: "fields",
        _attrs: {
          namespace: namespace,
        },
        _content: data.map(field => {
            const {value, ...props}= field
            return {
                _name: "item",
                _content: value,
                _attrs: props
            }
        })
      }, xmlOptions);

    data = new Blob([data], {
        type: 'application/xml'
    });
    return jsDownload(data, `${name}_xml.xml`, "text/xml");
};