import BootstrapTable from "react-bootstrap-table-next";
import {memo} from "react";
import {downloadCsv, downloadJson, downloadXml} from "../helpers";
import {ButtonGroup} from "react-bootstrap";
import {VscJson} from "react-icons/vsc";
import {SiMicrosoftexcel} from "react-icons/si";
import {BsCodeSlash} from "react-icons/bs";


const MetaDataTable = memo(({data = [], groupName, fileName}) => {
    fileName = fileName.replace(/\.[^/.]+$/, "");
    const columns  = [
         {
            dataField: 'ns',
            text: 'Typ',
            sort: true,
            hidden: true
        },
        {
            dataField: 'key',
            text: 'Kľúč',
            sort: true
        },
        {
            dataField: 'value',
            text: 'Hodnota',
            sort: true
        }
    ];

    const defaultSorted = [{
		dataField: 'ns',
		order: 'asc'
	}];

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h6 className="mb-1">{groupName}</h6>
                <ButtonGroup size="sm">
                    <button onClick={e => downloadJson(data, `${fileName}_${groupName}`)} className="btn btn-secondary px-1">
                        <VscJson size={18} className="align-middle"/>
                        <span className="ms-0 align-middle">JSON</span>
                    </button>
                    <button onClick={e => downloadCsv(data, ['key', 'value'], `${fileName}_${groupName}`)} className="btn btn-secondary px-1">
                        <SiMicrosoftexcel size={18}  className="align-middle" />
                        <span className="ms-1 align-middle">CSV</span>
                    </button>
                    <button onClick={e => downloadXml(data, `${fileName}_${groupName}`, groupName)} className="btn btn-secondary px-1">
                        <BsCodeSlash size={18}  className="align-middle" />
                        <span className="ms-1 align-middle">XML</span>
                    </button>
                  </ButtonGroup>
            </div>
            <BootstrapTable
                keyField='key'
                wrapperClasses="table-responsive shadow rounded"
                classes={"bg-white mb-0"}
                reponsive
                bootstrap5
                bordered={false}
                loading={false}
                data={data}
                columns={ columns }
                condensed
                defaultSorted={defaultSorted}
                defaultSortDirection="asc"
                noDataIndication={<h5 className="text-center">Žiadne dáta</h5>}
            />
            <div>

            </div>
        </div>

    );
});

export default MetaDataTable;