import React from 'react';
import ReactFileReader from 'react-file-reader';
import sha1  from 'js-sha1';
import { PDFDocument, PDFName } from 'pdf-lib';
import '../../src/FileArrayBuffer.js';
import {FileDrop} from "react-file-drop";
import XMP from "../XMP";


const DropFileUpload = ({children, onImport, splitPdf = true}) => {
	const handleDropFiles = (files, e, splitPdf = true) => {
		return handleFiles({fileList: files}, onImport, splitPdf);
	};

	return (
		<FileDrop
			className="file-drop"
			targetClassName="target"
			onDrop={(files, e) => handleDropFiles(files, e, splitPdf)}
		>
			{children}
		</FileDrop>
	);
};


const ClickFileUpload = ({onImport, splitPdf = true, children}) => {
	const handleButtonUploadFiles = files => handleFiles(files, onImport, splitPdf);

	return (
		<ReactFileReader
			handleFiles={handleButtonUploadFiles}
			base64
			fileTypes={[".png", ".jpg", ".tiff", ".pdf"]}
			multipleFiles={false}
		>
			{children}
		</ReactFileReader>
	);
};

export async function handleFiles(files, callback, splitPdf = true) {
	for (let i = 0; i < files.fileList.length; i++) {
		let fl = files.fileList.item(i);
		let cleanData = await fl.arrayBuffer();
		let data = `data:${fl.type};base64,${bufferToBase64(cleanData)}`;
		
		const xmp = new XMP(cleanData);
		const metadata = {
			raw: xmp.find(),
			parsed: xmp.parse()
		};

		if (fl.type === 'application/pdf') {
			let pages = await slicePdfToPages(data, fl);

			// Single page pdf document doesnt care about spliting
			if (pages.length === 1) {
				callback(pages[0], metadata);
				continue;
			}

			splitPdf ? pages.forEach(page => callback(page, metadata)) : callback(pages, metadata);
		} else if (fl.type === 'image/png' || fl.type === 'image/jpeg' || fl.type === 'image/jpg') {
			callback({name: fl.name, type: fl.type, page: 0, data: data, hash: sha1(data)}, metadata);
		} else {
			throw Error(`Not supported file type ${fl.type}`);
		}
	}
}

export async function slicePdfToPages(base64data, fl) {
	const originPdf = await PDFDocument.load(base64data, { ignoreEncryption: true });
	const ModDate = originPdf.getInfoDict().get(PDFName.of('ModDate'));
	let pdfDate = new Date('1970-01-01');
	try {
		let strDate = String(ModDate);
		pdfDate = new Date(`${strDate.slice(3, 7)}-${strDate.slice(7, 9)}-${strDate.slice(9, 11)}`);
	} catch (e) {}

	let pages = [];
	for (let p = 1; p <= originPdf.getPages().length; p++) {
		const pdfDoc = await PDFDocument.create();
		pdfDoc.setProducer('AccountingSoftware');
		pdfDoc.setCreator('AccountingSoftware');
		pdfDoc.setCreationDate(pdfDate);
		pdfDoc.setModificationDate(pdfDate);
		const [currentPage] = await pdfDoc.copyPages(originPdf, [p-1]);
		await pdfDoc.insertPage(0, currentPage);
		const pdfDataUrl = "data:application/pdf;base64," + await pdfDoc.saveAsBase64();

		pages.push({name: fl.name, type: fl.type, page: p, data: pdfDataUrl, hash: sha1(pdfDataUrl)})
	}
	return pages
}

export function bufferToBase64(buf) {
	let binstr = Array.prototype.map.call(new Uint8Array(buf), function (ch) {
		return String.fromCharCode(ch);
	}).join('');
	return btoa(binstr);
}


export {
    ClickFileUpload,
    DropFileUpload,
}