import logo from '../../assets/logo.svg';
import {useDidUpdate, useWindowScrollPosition} from "rooks";
import React, {memo, useState} from "react";
import {Modal, Navbar} from "react-bootstrap";
import { MdSendToMobile } from 'react-icons/md';
import { Sling as Hamburger } from 'hamburger-react';
import {BsTrash} from "react-icons/bs";
import {AiOutlineHome} from "react-icons/ai";


const NavBar = memo(({children, onFileRemove, hasFile = false, stuckOffset = 150}) => {
    const { scrollY } = useWindowScrollPosition();
	const [scrollAtTop, setScrollAtTop] = useState(true);
	useDidUpdate(() => setScrollAtTop(v => scrollY < stuckOffset), [scrollY < stuckOffset]);

    return (
        <Navbar
			id="navigation-bar"
			collapseOnSelect={false}
			fixed="top"
			expand="lg"
			className={`justify-content-between g-2 px-md-1 navbar-sticky ${scrollAtTop ? 'navbar-floating' : 'navbar-stuck fixed-top'}`}
		>
			<div className="d-flex justify-content-start ms-xl-3 col-sm-0 col-lg-1 col-xl-2 col-xxl-2">
				<span className="link-primary d-lg-none">
					<HamburgerMenuOverlay
						iconOverlay={(isMenuOpen, toggleOpen) => (
							<Hamburger size={25} toggled={isMenuOpen} onToggle={toggleOpen}/>
						)}
					>
						<MainMenuItems/>
					</HamburgerMenuOverlay>
				</span>

				<a href="#" className="mr-auto d-flex align-items-center ps-1 text-decoration-none">
					<Navbar.Brand>
						<img src={logo} style={{height: 30}} alt="DocFlow.ai"/>
						<span className="ms-4 text-primary">MetaData reader</span>
					</Navbar.Brand>
				</a>
			</div>

			<div className="d-none d-lg-block">
				<MainMenuItems>
					{hasFile &&
						<li className="nav-item me-3">
							<a
								href="#"
								className="btn btn-danger"
								title="Odstrániť súbor"
								onClick={onFileRemove}
							>
								<BsTrash/>
								<span className="ms-1">Odstrániť</span>
							</a>
						</li>
                	}
				</MainMenuItems>
			</div>
			<div className="d-block d-lg-none">
				{children}
			</div>
		</Navbar>
    );
});


const MainMenuItems = ({children}) => {
	return (
		<ul className="navbar-nav ms-auto d-flex justify-content-center flex-sm-row pe-3 text-center">
			{children}
			<li className="nav-item me-3 my-1 my-sm-0">
				<a
					href="https://docflow.ai/"
					target="_blank"
					className="btn btn-outline-secondary"
					title="Domov"
				>
					<AiOutlineHome />
					<span className="ms-1">Domov</span>
				</a>
			</li>
			<li className="nav-item my-2 my-sm-0">
				<a
					href="https://app.docflow.ai/"
					target="_blank"
					className="btn btn-primary text-uppercase fw-bold"
					title="Aplikácia"
				>
					<MdSendToMobile />
					<span className="ms-1"> Aplikácia</span>
				</a>
			</li>
		</ul>
	);
};

const HamburgerMenuOverlay = memo(({children, iconOverlay, defaultOpen = false, ...props}) => {
	const [isMenuOpen, setMenuOpen] = useState(defaultOpen);
	const handleClose = () => setMenuOpen(false);
	const toggleOpen = open => setMenuOpen(open);
	const handleMenuItemClicked = e => {
		if (e.target.getAttribute('data-prevent-close')) {
			return;
		}
		setMenuOpen(false);
	};

	return (
		<>
			<span>
				{iconOverlay(isMenuOpen, toggleOpen)}
			</span>
			<Modal
				id="hamburger-menu"
				show={isMenuOpen}
				onHide={handleClose}
				animation={false}
				backdropClassName="d-none"
				contentClassName="shadow-none d-lg-none"
				fullscreen
				scrollable={false}
				{...props}
			>
				<Modal.Body>
					<div onClick={handleMenuItemClicked}>
						{children}
					</div>
				</Modal.Body>
      		</Modal>
		</>
	);
});

export default NavBar;