import {memo, useCallback, useMemo} from "react";

const DataBox = memo(({ x, y, width, height, scale = 1, id, value, verified = true, keyPosClass = "top", margin = 4}) => {
	const styles = useMemo(() => {
		return {
			left: (parseFloat(x) * scale) - 2,
			top: (parseFloat(y) * scale) - 2,
			width: (parseFloat(width) * scale) + 4,
			height: (parseFloat(height) * scale) + 4
		}
	}, [scale, x, y, width, height]);

	const copyToClipboard = useCallback((e) => {
		navigator.clipboard.writeText(value);
		e.target.classList.add("copied");
	}, [value]);
	const onEnter = (el) => {
		el.classList.add("active");
	};
	const onLeave = (el) => {
		el.classList.remove("copied");
		el.classList.remove("active");
	};

	return scale ?
		<div
			style={styles}
			className={`image-stage-databox image-stage-databox-${verified ? 1 : 0} ${keyPosClass}`}
			id={`dataFieldBox-${id}`}
			onClick={copyToClipboard}
			onMouseOver={(e) => onEnter(e.target)}
			onMouseLeave={(e) => onLeave(e.target)}
		>
			<div
				className="image-stage-databox-key"
				style={{width: (parseFloat(width)*scale)+(margin*2)}}
				onMouseLeave={(e) => onLeave(e.target.parentNode)}
			>
				{id}
				{/*<FormattedMessage id={`fields.${id}`} defaultMessage={id} />*/}
			</div>
	</div>
		: null;
});

export default DataBox