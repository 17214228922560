import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Document, Page, pdfjs} from 'react-pdf';
import {useDimensionsRef} from "rooks";
import {memo, useCallback, useRef, useState} from "react";
import {Fade} from "react-bootstrap";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



const FilePreview = memo(({ children, mimeType, src, isLoaded = false, onLoad, placeholder = false, descaleFactor = 1 }) => {
	const [ref, dimensions] = useDimensionsRef({updateOnScroll: false, updateOnResize: true});

	const imgRef = useRef(null);
	const [scale, setScale] = useState(1);

	const handleFileLoaded = useCallback((info) => {
		if (pdfMimes.includes(mimeType)) {
			const {originalWidth, originalHeight} = info;
			setScale(v => (dimensions?.width / originalWidth) * descaleFactor);
		} else if(imageMimes.includes(mimeType) && Boolean(imgRef?.current)) {
			// todo @see https://github.com/Aljullu/react-lazy-load-image-component/issues/32
			setScale(v => 1 * descaleFactor);
		}
		setTimeout(() => onLoad(), 750);
	}, [mimeType, dimensions?.width, descaleFactor]);

	return src ?
		<div ref={ref} className="image-stage w-100 mb-4 me-lg-5 bg-white rounded shadow overflow-hidden" style={{maxWidth: 900}}>
			<Fade in={isLoaded} timeout={1000}>
				<div style={{minHeight: dimensions?.width * 1.4142}} className="bg-white">
					{imageMimes.includes(mimeType) && dimensions?.width &&
						<LazyLoadImage
							ref={imgRef}
							src={src}
							width={dimensions?.width}
							style={{backgroundColor: 'white'}}
							className="image-stage-doc rounded shadow image-orientation-from-image"
							effect="blur"
							afterLoad={handleFileLoaded}
							delayMethod={"debounce"}
						/>
					}
					{pdfMimes.includes(mimeType) && dimensions?.width &&
						<Document file={{url: src}} loading="">
							<Page
								pageNumber={1}
								width={dimensions?.width}
								loading={""}
								onLoadSuccess={handleFileLoaded}
								renderAnnotationLayer={false}
								renderTextLayer={false}
							/>
						</Document>
					}
					{children(scale, isLoaded)}
				</div>
			</Fade>
		</div> : null;
});

const pdfMimes = [
	'application/pdf'
];

const imageMimes = [
	'image/png',
	'image/jpg',
	'image/jpeg'
];

const mimeTypes = [...pdfMimes, ...imageMimes];

export default FilePreview;